const tools = {
	compress(inputFile, callback) {
		const self = this;
		const reader = new FileReader();
		reader.readAsDataURL(inputFile);
		reader.onload = function(e) {
			//防止照片文件过大无法上传， 通过以下代码重新创建一个固定宽高的图片再上传
			var image = new Image();
			image.src = this.result; //转化为base64字符串
			self.base64img = image.src
			image.onload = function() {
				debugger
				var expectWidth = this.naturalWidth;
				var expectHeight = this.naturalHeight;
				if (this.naturalWidth > this.naturalHeight && this.naturalWidth > 800) {
					expectWidth = 800;
					expectHeight = (expectWidth * this.naturalHeight) / this.naturalWidth;
				} else if (this.naturalHeight > this.naturalWidth && this.naturalHeight > 1200) {
					expectHeight = 1200;
					expectWidth = (expectHeight * this.naturalWidth) / this.naturalHeight;
				}
				var canvas = document.createElement('canvas');
				var ctx = canvas.getContext('2d');
				canvas.width = expectWidth;
				canvas.height = expectHeight;
				ctx.drawImage(this, 0, 0, expectWidth, expectHeight);
				var dataurl = canvas.toDataURL('image/jpeg', 1);
				// 0到1之间的取值，主要用来选定图片的质量，默认值是0.92，超出    
				//范围也会选择默认值。
				// callback(dataurl)
				var arr = dataurl.split(','),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				//修改上传文件名，重新整理进fileList
				var newf = new File([u8arr], inputFile.name, {
					type: mime
				});
				newf.uid = inputFile.uid
				callback(newf)
			};
		};
	}
}

export default tools
