<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="personal">
		<div class="personal-list">
			<div class="personal-item">
				<div class="personal-item-label">
					头像
				</div>
				<div v-if="!visitType" @click="upLoadAvatar" class="personal-item-value">
					<img v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
					<img v-else :src="require('@/assets/images/mobile/common/avatar.png')" alt="">
				</div>
				<div class="personal-item-value" v-else>
					<img v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
					<img v-else :src="require('@/assets/images/mobile/common/avatar.png')" alt="">
				</div>
			</div>
			<div v-if="identityMb == '学生'" class="personal-item">
				<div class="personal-item-label">
					姓名
				</div>
				<div class="personal-item-value">
					<!-- <input v-model="userInfo.name" :disabled="true" type="text" placeholder="请输入姓名"> -->
					<span>{{userInfo.name}}</span>
				</div>
			</div>
			<div v-if="identityMb != '学生'" class="personal-item">
				<div class="personal-item-label">
					账号
				</div>
				<div class="personal-item-value">
					<!-- <input v-model="userInfo.account" :disabled="true" type="text" placeholder=""> -->
					<span>{{userInfo.account}}</span>
				</div>
			</div>
			<div v-if="identityMb == '学生'" class="personal-item">
				<div class="personal-item-label">
					学号
				</div>
				<div class="personal-item-value">
					<!-- <input v-model="!visitType?userInfo.account:userInfo.studentNumber" :disabled="true" type="text" placeholder="请输入学号"> -->
					<span>{{!visitType?userInfo.account:userInfo.studentNumber}}</span>
				</div>
			</div>
			<div v-if="identityMb != '学生'" class="personal-item">
				<div class="personal-item-label">
					学校
				</div>
				<div class="personal-item-value">
					<!-- <input v-model="userInfo.schoolName" :disabled="true" type="text" placeholder=""> -->
					<span>{{userInfo.schoolName}}</span>
				</div>
			</div>
			<div v-if="identityMb == '学生'" class="personal-item">
				<div class="personal-item-label">
					学院
				</div>
				<div class="personal-item-value">
					<!-- <input v-model="userInfo.collegeName" :disabled="true" type="text" placeholder=""> -->
					<span>{{userInfo.collegeName}}</span>
				</div>
			</div>
			<div v-if="identityMb == '学生'" class="personal-item">
				<div class="personal-item-label">
					班级
				</div>
				<div class="personal-item-value">
					<!-- <input v-model="userInfo.stClass" :disabled="true" type="text" placeholder="请输入班级"> -->
					<span>{{userInfo.stClass}}</span>
				</div>
			</div>
		</div>
		<div v-if="identityMb == '学生'" class="personal-list">
			<div class="personal-item">
				<div class="personal-item-label">
					联系方式
				</div>
				<div class="personal-item-value">
					<input v-if="!visitType" v-model="userInfo.phone" type="text" placeholder="请输入联系方式">
					<span v-else>{{userInfo.phone}}</span>
				</div>
			</div>
		</div>
		<div v-if="identityMb == '学生'&&!visitType" class="personal-mark">
			注：只可修改头像和联系方式，请保证手机号的真实性方便相关人 员与您联系
		</div>
		<div v-if="!visitType" @click="submit" class="personal-btn personal-btn-active">
			保存
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
	</div>
</template>
<script>
	import {
		upLoadFileMb
	} from "@/api/mobile/oss"
	import {
		schoolTeacherAccountEditMb,
		studentAccountEditMb,
		studentInfoMb,
	} from "@/api/mobile/mine"
	import {
		mapActions
	} from 'vuex'
	import storage from 'store'
	import tool from '@/utils/compressJpg'
	export default {
		data() {
			return {
				identityMb: "",
				userInfo: {},
				upLoadUrl: "",
				limitSize: 5,
				visitType: undefined,
				studentId: undefined

			}
		},
		mounted() {
			this.visitType = undefined
			this.studentId = undefined
			this.visitType = this.$route.query.visitType
			this.studentId = this.$route.query.studentId
			this.getUserInfo()
			// if (this.visitType) {
			// 	this.identityMb = '学生'
			// } else {
			// 	this.identityMb = storage.get("identityMb")
			// 	this.getUserInfo()
			// }
		},
		methods: {
			...mapActions(['mbUserInfo']),
			compressJpg(file) {
				return new Promise((resolve, rejecy) => {
					tool.compress(file,
						(newfile) => {
							console.log('newFile', newfile)
							resolve(newfile)
						})
				})
			},
			getUserInfo() {
				this.userInfo = {}
				if (this.visitType) {
					this.identityMb = '学生'
					studentInfoMb(this.studentId, {
						id: this.studentId
					}).then(res => {
						if (res.code == 0) {
							if (!res.data.schoolName) {
								this.$set(res.data, "schoolName", "浙江工业大学")
							}
							this.userInfo = res.data
						}
					})
				} else {
					this.identityMb = storage.get("identityMb")
					this.userInfo = storage.get("accessInfo")
					if (!this.userInfo.avatar) {
						this.$set(this.userInfo, "avatar", "")
					}
					if (!this.userInfo.schoolName) {
						this.$set(this.userInfo, "schoolName", "浙江工业大学")
					}
				}
			},
			submit() {
				let params = {}
				if (this.identityMb == "学校老师") {
					params = {
						avatar: this.userInfo.avatar
					}
					schoolTeacherAccountEditMb(params).then(async (res) => {
						if (res.code == 0) {
							this.$toast("个人资料修改成功")
							await this.mbUserInfo()
							this.getUserInfo()
						}
					})
				} else if (this.identityMb == "学生") {
					params = {
						avatar: this.userInfo.avatar,
						phone: this.userInfo.phone,
					}
					studentAccountEditMb(params).then(async (res) => {
						if (res.code == 0) {
							this.$toast("个人资料修改成功")
							await this.mbUserInfo()
							this.getUserInfo()
						}
					})
				}
			},
			upLoadAvatar() {
				document.querySelector(".uploadImage input").click();
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$toast("上传文件大小不能超过 5MB!")
					return false;
				}
				let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				// let fileType = file.type;
				if (fileType != "png" && fileType != "jpg" && fileType != "jpeg" && fileType != "webp") {
					this.$toast("上传文件只能是jpg、png、jpeg、webp格式!")
					return false;
				}
			},
			async handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				const formData = new FormData();
				// 压缩图片
				// let compressFile = await this.compressJpg(data.file)
				formData.append('file', data.file);
				upLoadFileMb(formData).then((res) => {
					console.log("上传成功", res)
					this.userInfo.avatar = res.data.accessUrl
					// this.userInfo.avatar =
					// 	"http://peer-circle.oss-cn-hangzhou.aliyuncs.com/misc/202209/db64560d68bb4909aef4aa9976f0b140.jpg"
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	/deep/input:disabled {
		color: black;
		opacity: 1;
	}

	.personal {
		display: flex;
		flex-direction: column;

		.personal-list {
			display: flex;
			flex-direction: column;
			background: #FFFFFF;
			padding: 0 0.28rem;
			box-sizing: border-box;
			margin-top: 0.16rem;

			.personal-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 0.94rem;
				border-bottom: 0.02rem solid #DCDFE6;

				.personal-item-label {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.4rem;
					color: #303133;
				}

				.personal-item-value {
					display: flex;
					align-items: center;

					img {
						width: 0.8rem;
						height: 0.8rem;
						border-radius: 0.4rem;
					}

					span {
						flex: 1;
						text-align: right;
					}

					input {
						flex: 1;
						border: 0;
						outline: none;
						text-align: right;
					}
				}
			}
		}

		.personal-list :last-child {
			border: 0;
		}

		.personal-mark {
			font-size: 0.24rem;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 0.34rem;
			color: #909399;
			margin: 0.28rem 0.28rem 0.64rem 0.28rem;
		}

		.personal-btn {
			width: 6.94rem;
			height: 0.8rem;
			background: rgba(67, 146, 250, 0.4);
			border-radius: 0.08rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0.32rem auto 0rem auto;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FFFFFF;
		}

		.personal-btn-active {
			background: #4392FA;
		}
	}
</style>
